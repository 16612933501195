import React, { useState } from "react";
import "./style.css";
import logo from "../../images/SIR.png";
import { useWeb3React } from "@web3-react/core";
import { Injected } from "../../Helpers/Injected";
import Abi from "../../Helpers/abi.json";
import TokenAbi from "../../Helpers/token.json";
import TokenModal from "./TokenModal";
import { list } from "../../Helpers/tokenlist";
import { FiChevronDown } from "react-icons/fi";
import toast from "react-hot-toast";

const isValid = (regex) => (input) => regex.test(input);
const numberRegex = /^\d*\.?\d*$/;
const isValidNumber = isValid(numberRegex);

const MainSection = () => {
  const { active, account, library, activate } = useWeb3React();

  const cAddress = "0x5246FE5FAfc5C39AB6B2f1C57ca180F9179735E4";
  const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";
  const DEFAULT_GAS_PRICE_GWEI = "7"; // Default gas price in Gwei

  const [data, setData] = useState({
    bnb: "",
    gart: "",
  });
  const [open, setOpen] = React.useState(false);
  const [currentToken, setCurrentToken] = useState(list[0]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const gartVal = currentToken.name === "BNB" ? 1628 : 7;

  //BUY WITH BNB
  const processBuy = async () => {
    if (!data.bnb || !data.gart) {
      toast.error("Please enter the correct value.");
      return;
    }
  
    const contract = await new library.eth.Contract(Abi, cAddress);
  
    let bnbValue = await library.utils.toWei(String(data.bnb), "ether");
    const toastid = toast.loading("Processing transaction...");
  
    const defaultGasPriceWei = library.utils.toWei(DEFAULT_GAS_PRICE_GWEI, "gwei");
  
    await contract.methods
      .buySIR()
      .send({ from: account, value: bnbValue, gasPrice: defaultGasPriceWei })
      .on("error", function (error) {
        toast.error("Something went wrong!", { id: toastid });
        console.log(error);
      })
      .then(function (receipt) {
        toast.success("Transaction completed successfully", { id: toastid });
        setData({ bnb: "", gart: "" });
      });
  };
  

  //BUY WITH USDT
  const buyWithUsdt = async () => {
    if (!data.bnb || !data.gart) {
      toast.error("Please enter correct value.");
      return;
    }

    const contract = await new library.eth.Contract(Abi, cAddress);
    const tokenContract = await new library.eth.Contract(TokenAbi, usdtAddress);

    let bnbValue = await library.utils.toWei(String(data.bnb), "ether");
    const toastid = toast.loading("Allow our protocol to use your token");
    const defaultGasPriceWei = library.utils.toWei(DEFAULT_GAS_PRICE_GWEI, "gwei");
    await tokenContract.methods
      .approve(cAddress, bnbValue)
      .send({ from: account, gasPrice: defaultGasPriceWei })
      .on("error", function (error) {
        // alert(error.message);
        toast.error("something went wrong!", { id: toastid });
        console.log(error);
      })
      .then(async function (receipt) {
        toast.loading("Processing Transaction..", { id: toastid });
        await contract.methods
          .buyWithUSDT(bnbValue)
          .send({ from: account, gasPrice: defaultGasPriceWei })
          .on("error", function (error) {
            toast.error("something went wrong!", { id: toastid });
            // alert(error.message);
            console.log(error);
          })
          .then(function (receipt) {
            toast.success("Transaction completed successfully", {
              id: toastid,
            });
            // alert(`${data.gart} SIR has been sent to your wallet.`);
            setData({ bnb: "", gart: "" });
          });
      });
  };

  const buyToken = async () => {
    if (active) {
      if (currentToken.name === "BNB") {
        processBuy();
      } else if (currentToken.name === "USDT") {
        buyWithUsdt();
      }
    } else {
      await activate(Injected);
    }
  };

  const importToken = () => {
  if (window.ethereum) {
    // MetaMask or Trust Wallet
    const chainId = 56; // Replace with the correct chain ID for the Binance Smart Chain (BSC)
    const tokenAddress = '0x36a68868fDda32D6ad7a3620557167FC204cf903'; // Replace with the token's contract address

    window.ethereum
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: 'SIR',
            decimals: 18, // Replace with the token's decimal places
            image: {logo}, // Replace with the token's image URL
          },
        },
      })
      .then((success) => {
        if (success) {
          console.log('Token added to wallet');
        } else {
          console.error('Token not added');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    // Handle the case where MetaMask or Trust Wallet is not installed
    console.error('MetaMask or Trust Wallet not detected');
  }
};

  return (
    <>
    <br></br>
    <br></br>
    <div className="main-section">
      <p className="headings">Smart Infinity Reward (SIR)</p>
      <p className="headings">Connect BEP20 Wallet</p>
      <div className="main-section-form">
        <p className="mb-6">
          1 {currentToken.name} = {gartVal} SIR
        </p>
        <p className="mgtp">Pay with</p>
        <div className="form-group">
          <input
            type="text"
            value={data.bnb}
            className="text-black"
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                bnb: val,
                gart: val * gartVal,
              });
            }}
          />
          <div
            onClick={handleOpen}
            className=" cursor-pointer items-center flex"
          >
            <img src={currentToken.icon} alt="snk" />
            <p>{currentToken.name}</p>
            <FiChevronDown className="text-black" />
          </div>
        </div>
        <p className="mgtp">You will get</p>
        <div className="form-group">
          <input
            type="text"
            className="text-black"
            value={data.gart}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                gart: val,
                bnb: val / gartVal,
              });
            }}
          />
          <div>
            <img src={logo} alt="snk" />
            <p>SIR</p>
          </div>
        </div>
        
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>

          <button className="buy" onClick={buyToken} style={{margin: "1px" }}>
            {account ? "Buy" : "Connect"}
          </button>

          <button className="buy import-token" onClick={importToken} style={{margin: "1px" }}>
             Import SIR
          </button>
        </div>

        <div className="smart">
          <i className="fa fa-lock" aria-hidden="true"></i>
          <p>100% Secure smart contract</p>
        </div>
      </div>

    
      
      <TokenModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        currentChain={currentToken}
        setCurrentChain={setCurrentToken}
        setData={setData}
      />
    </div>
    </>
  );
};

export default MainSection;
